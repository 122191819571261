import React, { useState } from "react"
import PropTypes from "prop-types"
import styled, { useTheme } from "styled-components"
import { InView } from "react-intersection-observer"
import media from "@styles/media"

import { ReactComponent as KlipsIconLogo } from "@images/comparison/klips-icon.svg"
import { ReactComponent as PowerMetricsIconLogo } from "@images/comparison/powermetrics-icon.svg"
import { ReactComponent as KlipsLogo } from "@images/comparison/klips.svg"
import { ReactComponent as PowerMetricsLogo } from "@images/comparison/powermetrics.svg"

import Flex from "./elements/flex"
import Span from "@components/elements/span"
import Heading from "./elements/heading"
import GradientText from "./common/gradient-text"
import Grid from "@components/elements/grid"
import Paragraph from "@components/elements/paragraph"
import Button from "@components/elements/button"
import { Tooltip } from "./common/tooltip"

const BoxLogo = styled(Flex)`
  align-items: flex-start;

  svg {
    transition: height 0.2s;
  }

  ${media.sm`
		svg {
			height: ${props => (props.showHeading ? "30px" : "40px")};
		}
		display: ${props => props.hideOnMobile && `none`};
	`}

  ${media.md`
		svg {
			height: ${props => (props.showHeading ? "30px" : "40px")};
		}
		display: ${props => props.hideOnMobile && `none`};
	`}

	${media.md_up`
		svg {
			height: ${props => (props.showHeading ? "40px" : "50px")};
		}
		display: ${props => !props.hideOnMobile && `none`};
	`}
`

BoxLogo.propTypes = {
  hideOnMobile: PropTypes.bool,
  showHeading: PropTypes.bool,
}

export const TableRow = ({ children, ...rest }) => {
  return (
    <Grid
      width="100%"
      alignItems="center"
      columns="repeat(3, 1fr)"
      padding="0 0 1rem"
      gap="1.5rem"
      {...rest}
    >
      {children}
    </Grid>
  )
}

TableRow.propTypes = {
  children: PropTypes.node,
}

export const TableEntry = ({ enabled, text }) => {
  return text ? (
    <Paragraph fontSize="1.1rem" lineHeight="1.5">
      {text}
    </Paragraph>
  ) : (
    <svg height="20" width="20">
      <use xlinkHref={enabled ? `#checkmark` : `#featureless`} />
    </svg>
  )
}

TableEntry.propTypes = {
  enabled: PropTypes.bool,
  text: PropTypes.string,
}

export const TableRowHeader = ({ hover, children }) => {
  return hover ? (
    <Flex
      lineHeight="1.5"
      fontWeight="700"
      fontSize="1.1rem"
      flexFlow="row"
      alignItems="center"
      gap="0.5rem 1rem"
      justifyContent="space-between"
    >
        <Span>{children}</Span>
        <Tooltip mobileAlignment={"center"}>{hover}</Tooltip>
    </Flex>
  ) : (
    <Flex
      lineHeight="1.5"
      fontWeight="700"
      fontSize="1.1rem"
      flexWrap="wrap"
      flexFlow="row"
      alignItems="center"
      gap="0.5rem 1rem"
    >
      <Span>{children}</Span>
    </Flex>
  )
}

TableRowHeader.propTypes = {
  hover: PropTypes.string,
  children: PropTypes.node,
}

const TableHeader = ({ title, highlight, headerInView, tableInView }) => {
  const { shadow } = useTheme()
  const showHeading = !headerInView && tableInView
  return (
    <TableRow
      padding="1rem"
      border={showHeading && `0 !important`}
      borderRadius={showHeading && `2rem`}
      background={showHeading && `white`}
      transition="border-radius .2s, box-shadow .4s"
      boxShadow={showHeading && shadow.soft}
      maxWidth="100%"
      position="sticky"
      top="6rem"
      zIndex={showHeading && 10}
    >
      {showHeading ? (
        <Heading as="h4" fontSize="1.5rem" mobileScale={0.8} tabletScale={0.9}>
          {title}&nbsp;
          <GradientText gradient="linear-gradient(90deg, #357ece 0%, #4bb4dd 100%)">
            {highlight}
          </GradientText>
        </Heading>
      ) : (
        <div></div>
      )}
      <Flex gap="1rem">
        <BoxLogo showHeading={showHeading}>
          <PowerMetricsIconLogo />
        </BoxLogo>
        <BoxLogo showHeading={showHeading} hideOnMobile>
          <PowerMetricsLogo />
        </BoxLogo>
        {showHeading && (
          <Button.PowerMetricsSimple
            hideOnSm
            hideOnMd
            fontSize="0.9rem"
            notice={false}
            padding="0 1rem"
            color="#343c8d"
            background="#edf3fd"
          >
            Get Started
          </Button.PowerMetricsSimple>
        )}
      </Flex>
      <Flex gap="1rem">
        <BoxLogo showHeading={showHeading}>
          <KlipsIconLogo />
        </BoxLogo>
        <BoxLogo showHeading={showHeading} hideOnMobile>
          <KlipsLogo />
        </BoxLogo>
        {showHeading && (
          <Button.KlipsSimple
            hideOnSm
            hideOnMd
            fontSize="0.9rem"
            notice={false}
            padding="0 1rem"
            color="#1178a1"
            background="#e0f8ff"
          >
            Start Trial
          </Button.KlipsSimple>
        )}
      </Flex>
    </TableRow>
  )
}

TableHeader.propTypes = {
  title: PropTypes.string,
  highlight: PropTypes.string,
  headerInView: PropTypes.bool,
  tableInView: PropTypes.bool,
}

const Table = styled(Flex)`
  > div:not(:last-child) {
    border-bottom: 1px solid #ccc;
  }
`

export const ComparisonTable = ({ title, highlight, children }) => {
  const [headerInView, setHeaderInView] = useState(false)
  const [tableInView, setTableInView] = useState(false)

  return (
    <Flex gap="2rem">
      <InView rootMargin="-48px 0px 0px 0px" onChange={setHeaderInView}>
        <Heading center as="h2" fontSize="3rem">
          {title}{" "}
          <GradientText gradient="linear-gradient(90deg, #357ece 0%, #4bb4dd 100%)">
            {highlight}
          </GradientText>
        </Heading>
      </InView>
      <InView onChange={setTableInView}>
        <Table gap="1rem">
          <TableHeader
            title={title}
            highlight={highlight}
            headerInView={headerInView}
            tableInView={tableInView}
          />
          {children}
        </Table>
      </InView>
    </Flex>
  )
}

ComparisonTable.propTypes = {
  title: PropTypes.string,
  highlight: PropTypes.string,
  children: PropTypes.node,
}
