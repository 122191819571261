import React from "react"
import Layout from "@components/layout"
import PropTypes from "prop-types"
import Flex from "@components/elements/flex"
import { graphql } from "gatsby"
import Container from "@components/layout/container"
import { AlternateTable } from "@components/alternative-table"
import Heading from "@components/elements/heading"
import GradientText from "@components/common/gradient-text"
import Paragraph from "@components/elements/paragraph"
import Span from "@components/elements/span"
import Html from "@components/html"
import Image from "@components/image"
import styled, { useTheme } from "styled-components"
import Gradient from "@images/pm-standard-gradient.png"
import { Accordion } from "@components/common/accordion"
import Button from "@components/elements/button"
import { PowerMetricsEOPCta } from "@components/cta/eop/powermetrics"

const ImageGradient = styled.img`
  position: absolute;
  left: 50%;
  top: 0%;
  transform: translate(-50%, -50%);
  min-width: 2500px;
  z-index: 0;
  height: auto;
`

const FrequentQuestions = ({ questions }) => {
  return (
    <Flex textContainer>
      <Heading
        style={{ alignSelf: "center" }}
        as="h2"
        fontSize="3rem"
        margin="12rem 0 2rem"
      >
        Frequently Asked Questions
      </Heading>
      {questions.map((question, i) => {
        return (
          <Accordion key={`accordion-${i}`} title={question.question}>
            <Flex fontSize="1.1rem" gap="1rem">
              <Html inherit parseElements html={question.answer} />
            </Flex>
          </Accordion>
        )
      })}
    </Flex>
  )
}

FrequentQuestions.propTypes = {
  questions: PropTypes.array.isRequired,
}

const Alternate = ({ data, pageContext }) => {
  const page = data.alternate
  const { shadow } = useTheme()

  return (
    <Layout
      fullWidth
      marginless
      canonical={pageContext.slug}
      title={page.seo.title}
      description={page.seo.description}
    >
      <svg display="none">
        <symbol height="20" width="20" id="checkmark" viewBox="0 0 27 20">
          <path
            d="M8.58044 15.7793L2.17965 9.56003L0 11.6629L8.58044 20L27 2.10291L24.8357 0L8.58044 15.7793Z"
            fill="#404BA8"
          />
        </symbol>
        <symbol height="20" width="20" id="featureless" viewBox="0 0 20 20">
          <path
            d="M10 0C4.48597 0 0 4.48597 0 10C0 15.514 4.48597 20 10 20C15.514 20 20 15.514 20 10C20 4.48603 15.514 0 10 0ZM5.50784 3.83419C6.76923 2.91264 8.32176 2.36693 10 2.36693C14.2089 2.36693 17.6331 5.79109 17.6331 10C17.6331 11.6782 17.0874 13.2308 16.1658 14.4922L5.50784 3.83419ZM3.83419 5.50784L14.4921 16.1659C13.2307 17.0875 11.6782 17.6331 10 17.6331C5.79109 17.6331 2.36693 14.2089 2.36693 10.0001C2.36693 8.32176 2.91264 6.76928 3.83419 5.50789V5.50784Z"
            fill="#404BA8"
          />
        </symbol>
      </svg>
      <Flex width="100%" position="relative" overflow="hidden">
        <Image
          eager
          style={{ margin: "12rem auto 6rem", maxWidth: "1200px" }}
          file={page.image}
        />
        <ImageGradient src={Gradient} />
        <Container text>
          <Heading margin="1rem 0 2rem">{page.h1}</Heading>
          <Flex gap="2rem" margin="0 0 12rem">
            <Html parseElements html={page.description} />
          </Flex>
        </Container>
      </Flex>
      <Container>
        <Flex
          gap="2rem"
          margin="4rem 0 10rem"
          marginMd="2rem 0 6rem"
          marginSm="1rem 0 4rem"
        >
          <Flex gap="10rem">
            {page.rows.map((row, i) => {
              return (
                <AlternateTable
                  image1={page.product_1_image}
                  image2={page.product_2_image}
                  smallImage1={page.product_1_image_small}
                  smallImage2={page.product_2_image_small}
                  key={i}
                  content={row}
                />
              )
            })}
          </Flex>
          <Container text>
            <Heading
              style={{ alignSelf: "center" }}
              as="h2"
              fontSize="4rem"
              margin="12rem 0 2rem"
            >
              Why Klipfolio
              <br />
              PowerMetrics is the&nbsp;
              <GradientText gradient="linear-gradient(90deg, #9D7AF3 0%, #ECA5C3 100%)">
                best alternative
              </GradientText>
              &nbsp;to
              <br />
              {page.product_1} & {page.product_2}
            </Heading>
            <Paragraph margin="0 0 2rem">
              At Klipfolio, we prioritize the needs and requirements of our
              clients above all else. Thanks to continuous upgrades, we improve
              our software every day to ensure that you&apos;re getting all the
              features you want without the added premiums.
            </Paragraph>
          </Container>
          <Flex gap="10rem" gapMd="8rem" gapSm="6rem">
            {page.blocks.map((block, i) => {
              return (
                <Container key={`block-${i}`}>
                  <Image
                    style={{ margin: "0 auto", maxWidth: "900px" }}
                    file={block.image}
                  />
                  <Container text>
                    <Heading as="h2" margin="0 0 2rem">
                      {block.h2}
                    </Heading>
                    <Flex gap="2rem">
                      <Html parseElements html={block.description} />
                      {block.cta && (
                        <Button.PowerMetrics alignSelf="flex-start" notice>
                          {block.cta}
                        </Button.PowerMetrics>
                      )}
                    </Flex>
                  </Container>
                </Container>
              )
            })}
          </Flex>
          <Container text style={{ margin: "12rem auto" }}>
            <Image
              eager
              radius={1}
              style={{ maxWidth: "70%", margin: "0 0 4rem" }}
              file={data.review}
            />
            <Heading as="h2" fontSize="4rem" margin="0 0 2rem">
              Klipfolio is trusted
              <br />
              across industries.
            </Heading>
            <Paragraph margin="0 0 5rem">
              PowerMetrics has been helping teams just like yours organize,
              visualize, and share metrics since 2019. With extensive docs, an
              AI-powered help UI, friendly support staff, a free tier, and low
              pricing plans, now&apos;s the time to give us a try.
            </Paragraph>
            <Paragraph fontWeight={600} margin="0 0 1rem">
              &quot;We&apos;ve grown by over 300% since using Klipfolio, and the
              decisions that led to this growth have come from data displayed on
              Klipfolio.&quot;
            </Paragraph>
            <Flex margin="0 0 5rem">
              <Paragraph fontWeight={700} fontSize="1rem" lineHeight="1.5">
                Marcus Taylor
              </Paragraph>
              <Span opacity={0.6} fontSize="0.9rem" lineHeight="1.5">
                Founder and CEO of Venture Harbour
              </Span>
            </Flex>
            <Paragraph fontWeight={600} margin="0 0 1rem">
              &quot;Our leadership team is making smarter decisions and were on
              target to grow by 40 - 50% this year thanks to our insights from
              Klipfolio.&quot;
            </Paragraph>
            <Flex margin="0 0 5rem">
              <Paragraph fontWeight={700} fontSize="1rem" lineHeight="1.5">
                Brian Dainis
              </Paragraph>
              <Span opacity={0.6} fontSize="0.9rem" lineHeight="1.5">
                Founder and CEO of Curotec
              </Span>
            </Flex>
            <Paragraph fontWeight={600} margin="0 0 1rem">
              &quot;I love the full range of capabilities that are possible when
              building a Klipfolio dashboard (specifically with their Klips
              product). Unlike the majority of BI platforms, building with
              Klipfolio feels more like designing a web page than configuring a
              single chart at a time. [...] Variables are straightforward and
              can be made independent of the data, which makes room for creative
              solutions to a wide variety of UI requirements.&quot;
            </Paragraph>
            <Flex>
              <Paragraph fontWeight={700} fontSize="1rem" lineHeight="1.5">
                Graham H
              </Paragraph>
              <Span opacity={0.6} fontSize="0.9rem" lineHeight="1.5">
                Principal Consultant for SMBs
              </Span>
            </Flex>
          </Container>
          <Container text style={{ margin: "0 auto 12rem" }}>
            <Paragraph fontSize="2rem" fontWeight={600} center>
              &quot;Klipfolio is data analytics software that is best suited for
              businesses of all sizes that are looking to get instant insights
              from their data&quot;
            </Paragraph>
            <Flex
              flexFlow="row"
              alignItems="center"
              justifyContent="center"
              gap="1rem"
              margin="2rem 0 0"
            >
              <Image
                height={40}
                width={40}
                noDimensions
                file={data.forbesLogo}
                radius={100}
                borderStyle={{ boxShadow: shadow.default }}
              />
              <Span color="black">Forbes Magazine</Span>
            </Flex>
          </Container>
          {page.pricing_rows?.map((row, i) => {
            return (
              <AlternateTable
                image1={page.product_1_image}
                image2={page.product_2_image}
                smallImage1={page.product_1_image_small}
                smallImage2={page.product_2_image_small}
                key={i}
                content={row}
              />
            )
          })}
          <Container text>
            <Flex gap="2rem">
              <Html parseElements html={page.pricing_description} />
            </Flex>
          </Container>
          {page.faq && <FrequentQuestions questions={page.faq} />}
        </Flex>
      </Container>
      <PowerMetricsEOPCta />
    </Layout>
  )
}

Alternate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default Alternate

export const pageQuery = graphql`
  query alternateQuery($id: String!) {
    review: directusFiles(
      directus_id: { eq: "b72ab500-4c96-4579-b942-332d48f5e741" }
    ) {
      cdn
    }
    gradient: directusFiles(
      directus_id: { eq: "7baca979-08e0-4719-b564-591fb22b1b72" }
    ) {
      cdn
    }
    forbesLogo: directusFiles(
      directus_id: { eq: "7dfa1796-00f7-4817-b2e1-0f8a8bc39f48" }
    ) {
      cdn
    }
    alternate(id: { eq: $id }) {
      seo {
        ...seoData
      }
      image {
        cdn(width: 1200)
      }
      h1
      description
      rows {
        heading
        value {
          compare {
            column
            has_feature
          }
          hidden
          key
        }
      }
      pricing_rows {
        heading
        value {
          compare {
            column
            has_feature
          }
          key
        }
      }
      pricing_header
      pricing_description
      product_1
      product_2
      product_1_image {
        cdn
      }
      product_2_image {
        cdn
      }
      product_1_image_small {
        cdn
      }
      product_2_image_small {
        cdn
      }
      blocks {
        description
        h2
        image {
          cdn
        }
        link
        subtitle
      }
      faq {
        question
        answer
      }
    }
  }
`
