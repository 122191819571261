import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"
import styled, { useTheme } from "styled-components"
import { InView } from "react-intersection-observer"
import media from "@styles/media"
import { ReactComponent as PowerMetricsIconLogo } from "@images/comparison/powermetrics-icon.svg"
import { ReactComponent as PowerMetricsLogo } from "@images/comparison/powermetrics.svg"
import Flex from "./elements/flex"
import Span from "@components/elements/span"
import Heading from "./elements/heading"
import Grid from "@components/elements/grid"
import Button from "@components/elements/button"
import Image from "@components/image"
import { TableEntry } from "./comparison-table"

const BoxLogo = styled(Flex)`
  align-items: flex-start;

  svg,
  img {
    transition: height 0.2s;
  }

  ${media.sm`
    svg, img {
      height: ${props => (props.showHeading ? "30px" : "40px")};
    }
    img {
      align-self: center;
    }
    display: ${props => props.hideOnMobile && `none`};
  `}

  ${media.md`
    svg, img {
      height: ${props => (props.showHeading ? "30px" : "40px")};
    }
    img {
      align-self: center;
    }
    display: ${props => props.hideOnMobile && `none`};
  `}

  ${media.md_up`
    svg {
      height: ${props => (props.showHeading ? "40px" : "50px")};
    }
    display: ${props => !props.hideOnMobile && `none`};
  `}
`

BoxLogo.propTypes = {
  hideOnMobile: PropTypes.bool,
  showHeading: PropTypes.bool,
}

export const TableRow = ({ length, children, ...rest }) => {
  return (
    <Grid
      width="100%"
      alignItems="center"
      columns={length === 2 ? "3fr 2fr 2fr 3fr" : "2fr 2fr 2fr"}
      padding="0 0 1rem"
      gap="1.5rem"
      {...rest}
    >
      {children}
    </Grid>
  )
}

TableRow.propTypes = {
  length: PropTypes.number,
  children: PropTypes.node,
}

export const AlternativeTableRowHeader = ({ children }) => {
  return (
    <Flex
      lineHeight="1.5"
      fontWeight="700"
      fontSize="1rem"
      flexWrap="wrap"
      flexFlow="row"
      alignItems="center"
      gap="0.5rem 1rem"
    >
      <Span>{children}</Span>
    </Flex>
  )
}

AlternativeTableRowHeader.propTypes = {
  children: PropTypes.node,
}

const AlternativeTableHeader = ({
  title,
  headerInView,
  tableInView,
  image1,
  image2,
  smallImage1,
  smallImage2,
}) => {
  const { shadow } = useTheme()
  const showHeading = !headerInView && tableInView
  return (
    <TableRow
      padding="1.5rem 1rem"
      border={showHeading && `0 !important`}
      borderRadius={showHeading && `2rem`}
      background={showHeading && `white`}
      transition="border-radius .2s, box-shadow .4s"
      boxShadow={showHeading && shadow.soft}
      maxWidth="100%"
      position="sticky"
      top="6rem"
      zIndex={10}
      length={image2 ? 2 : 1}
    >
      {showHeading ? (
        <Heading as="h4" fontSize="1.5rem" mobileScale={0.8} tabletScale={0.9}>
          {title}
        </Heading>
      ) : (
        <div></div>
      )}
      <Flex gap="1rem">
        <BoxLogo showHeading={showHeading}>
          <Image
            style={{ maxHeight: "none" }}
            objectPosition="left"
            file={smallImage1}
            objectFit="scale-down"
            height={40}
          />
        </BoxLogo>
        <BoxLogo showHeading={showHeading} hideOnMobile>
          <Image style={{ maxHeight: "none" }} file={image1} width={140} />
        </BoxLogo>
      </Flex>
      {image2 && (
        <Flex gap="1rem">
          <BoxLogo showHeading={showHeading}>
            <Image
              style={{ maxHeight: "none" }}
              objectPosition="left"
              file={smallImage2}
              objectFit="scale-down"
              height={40}
            />
          </BoxLogo>
          <BoxLogo showHeading={showHeading} hideOnMobile>
            <Image style={{ maxHeight: "none" }} file={image2} width={140} />
          </BoxLogo>
        </Flex>
      )}
      <Flex gap="1rem">
        <BoxLogo showHeading={showHeading}>
          <PowerMetricsIconLogo />
        </BoxLogo>
        <BoxLogo showHeading={showHeading} hideOnMobile>
          <PowerMetricsLogo width={"180px"} />
        </BoxLogo>
      </Flex>
    </TableRow>
  )
}

AlternativeTableHeader.propTypes = {
  title: PropTypes.string,
  headerInView: PropTypes.bool,
  tableInView: PropTypes.bool,
  image1: PropTypes.object.isRequired,
  image2: PropTypes.object.isRequired,
  smallImage1: PropTypes.object.isRequired,
  smallImage2: PropTypes.object.isRequired,
}

const Table = styled(Flex)`
  > div:not(:last-child) {
    border-bottom: 1px solid #ccc;
  }
`

export const AlternativeTable = ({
  title,
  children,
  image1,
  image2,
  smallImage1,
  smallImage2,
}) => {
  const [headerInView, setHeaderInView] = useState(false)
  const [tableInView, setTableInView] = useState(false)

  return (
    <Flex gap="2rem">
      <InView rootMargin="-48px 0px 0px 0px" onChange={setHeaderInView}>
        <Heading center as="h2" fontSize="3rem">
          {title}
        </Heading>
      </InView>
      <InView onChange={setTableInView}>
        <Table gap="1rem">
          <AlternativeTableHeader
            title={title}
            headerInView={headerInView}
            tableInView={tableInView}
            image1={image1}
            image2={image2}
            smallImage1={smallImage1}
            smallImage2={smallImage2}
          />
          {children}
        </Table>
      </InView>
    </Flex>
  )
}

AlternativeTable.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  image1: PropTypes.object.isRequired,
  image2: PropTypes.object.isRequired,
  smallImage1: PropTypes.object.isRequired,
  smallImage2: PropTypes.object.isRequired,
}

export const AlternateTable = ({
  image1,
  image2,
  smallImage1,
  smallImage2,
  content,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = useCallback(() => setIsOpen(wasOpen => !wasOpen), [setIsOpen])
  const { color } = useTheme()

  let isHiddenDisplayed = false

  return (
    <Flex gap="2rem">
      <AlternativeTable
        title={content.heading}
        image1={image1}
        image2={image2}
        smallImage1={smallImage1}
        smallImage2={smallImage2}
      >
        {content.value.map((column, rowI) => {
          if (!isHiddenDisplayed || isOpen) {
            if (rowI > 3) {
              isHiddenDisplayed = true
            }

            return (
              <TableRow
                style={{
                  WebkitMaskImage:
                    isHiddenDisplayed &&
                    !isOpen &&
                    `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0) 80%)`,
                }}
                key={`table-row-${rowI}`}
                length={image2 ? 2 : 1}
              >
                <AlternativeTableRowHeader>
                  {column.key}
                </AlternativeTableRowHeader>
                {column.compare.map((value, i) => (
                  <TableEntry
                    enabled={value.column ? null : value.has_feature}
                    text={value.column}
                    key={`table-value-${rowI}-${i}`}
                  />
                ))}
              </TableRow>
            )
          }
        })}
      </AlternativeTable>
      {isHiddenDisplayed && (
        <Button
          notice={false}
          style={{ margin: "0 auto" }}
          color={color.indigo600}
          background="linear-gradient(90deg, #FCE9E9 -15.94%, #DDF0FD 123.58%)"
          onClick={toggle}
        >
          View more features +
        </Button>
      )}
    </Flex>
  )
}

AlternateTable.propTypes = {
  image1: PropTypes.object.isRequired,
  image2: PropTypes.object.isRequired,
  smallImage1: PropTypes.object.isRequired,
  smallImage2: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
}
